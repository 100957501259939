@font-face {
    font-family: 'ContinentalStagSansA';
    src:
      url('./files/ContinentalStagSansA-LightIt.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-LightIt.woff') format('woff');
    font-display: swap;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'ContinentalStagSansA';
    src:
      url('./files/ContinentalStagSansA-Light.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-Light.woff') format('woff');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ContinentalStagSansA';
    src:
      url('./files/ContinentalStagSansA-Medium.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-Medium.woff') format('woff');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ContinentalStagSansA';
    src:
      url('./files/ContinentalStagSansA-MediumIt.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-MediumIt.woff') format('woff');
    font-display: swap;
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'ContinentalStagSansA Book';
    src:
      url('./files/ContinentalStagSansA-Book.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-Book.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ContinentalStagSansA Book';
    src:
      url('./files/ContinentalStagSansA-BookIt.woff2') format('woff2'),
      url('./files/ContinentalStagSansA-BookIt.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}
